import React, { FC, useEffect, useState } from "react";
import { useAppDispatch } from "../../../store";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { TableHeightWrapper } from "../../layout/content_components/TableHeightWrapper";
import { Alert, Box, Stack } from "@mui/material";
import { PaginatorRow } from "../../layout/content_components/PaginatorRow";
import { AgentsTransfersCard } from "../../agent_transfers/agents_transfers_list/AgentsTransfersCard";
import { resetUserTransfers } from "../../../features/user_transfers/UserTransfersSlice";
import { useUserTransfers } from "../../../features/user_transfers/useUserTransfers";
import { GeneralInfoCard } from "../../commons/GeneralInfoCard";
import {convertCentsToCurrencyString} from "../../../features/common_funcs";

export const UserTransfersList: FC = () => {
    const dispatch = useAppDispatch()
    const { languagePack: {pack: {agentTransfersTable: lang, playersAgents: playersAgentsLang}}} = useLanguage()
    const { xxSmallScreen } = useCommonMediaQueries()
    const [height, setHeight] = useState<number>(0)
    const { isUserTransfersLoading, userTransfers, totalPages, currentPage, pageSize, usersTransfersInfo } = useUserTransfers()

    useEffect(() => {
        return () => {
            dispatch(resetUserTransfers())
        }
    }, [dispatch])

    return <TableHeightWrapper onHeightChanged={setHeight} currentHeight={height}>
        <Box height={height} sx={{
            backgroundColor: 'rgba(0,0,0,0.05)',
            overflowY: 'auto'
        }}>
            <Stack
                justifyContent={'space-between'}
                direction={'row'}
                gap={'5px'}
                padding={'5px'}
                flexWrap={'wrap'}
            >
                <Stack padding={'8px'} gap={'8px'} width={'100%'}>
                    <Box flex={1}>
                        <GeneralInfoCard title={playersAgentsLang.totalDeposits}
                                         value={usersTransfersInfo.totalDeposits !== null ? convertCentsToCurrencyString(usersTransfersInfo.totalDeposits, true) : undefined}
                                         suffix={'ARS'}/>
                    </Box>
                    <Box flex={1}>
                        <GeneralInfoCard title={playersAgentsLang.totalWithdrawals}
                                         value={usersTransfersInfo.totalWithdraws !== null ? convertCentsToCurrencyString(usersTransfersInfo.totalWithdraws, true) : undefined}
                                         suffix={'ARS'}/>
                    </Box>
                    <Box flex={1}>
                        <GeneralInfoCard title={playersAgentsLang.netDeposit}
                                         value={usersTransfersInfo.totalDeposits !== null && usersTransfersInfo.totalWithdraws !== null ? convertCentsToCurrencyString((usersTransfersInfo.totalDeposits - usersTransfersInfo.totalWithdraws), true) : undefined}
                                         suffix={'ARS'}/>
                    </Box>
                </Stack>
                {xxSmallScreen && userTransfers !== null && userTransfers.length > 10 && !isUserTransfersLoading && <Box width={'100%'}>
                    <PaginatorRow totalPages={totalPages} currentPage={currentPage} pageSize={pageSize}/>
                </Box>}
                {
                    userTransfers !== null && !isUserTransfersLoading && userTransfers.map(
                        transfer => <Box
                            width={xxSmallScreen ? '100%' : 'calc((100% - 10px) / 2)'}
                            key={`userID-${transfer.transfer_id}`}
                        >
                            <AgentsTransfersCard transfer={transfer}/>
                        </Box>
                    )
                }
                {  userTransfers !== null && userTransfers.length === 0 &&
                    <Alert variant="outlined" severity="warning" sx={{width: '100%'}}>
                        {lang.transfersNotFoundMessage}
                    </Alert>}
                {xxSmallScreen && <Box width={'100%'}>
                    <PaginatorRow totalPages={totalPages} currentPage={currentPage} pageSize={pageSize}/>
                </Box>}
            </Stack>
        </Box>
    </TableHeightWrapper>
}