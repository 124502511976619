import React, {FC, useEffect, useState} from "react";
import {Box, Card, CircularProgress, Typography, useTheme} from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import {Widget} from "../../commons/widget_layout/Widget";
import {useGeneralInfo} from "../../../features/general_info/useGeneralInfo";
import {useUser} from "../../../features/user/useUser";
import {useLanguage} from "../../../features/localisation/useLanguage";
import {groupDataByDay} from "../../../features/general_info/funcs";
import Stack from "@mui/material/Stack";
import {convertCentsToCurrencyString} from "../../../features/common_funcs";
import {Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {TimeFilter} from "../../commons/time_filter/TimeFilter";
import {MiniButton, MoneySurfaceView} from "../../../themes/theme_connector";
import {useCommonMediaQueries} from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {UsernameFilter} from "../../commons/username_filter/UsernameFilter";

interface CustomTooltipProps {
    active?: boolean;
    payload?: any[];
    label?: string;
}

interface FinancialDailyWidgetProps {
    height?: string
}

export const FinancialDailyWidget: FC<FinancialDailyWidgetProps> = ({height}) => {
    const theme = useTheme()
    const [widgetWidth, setWidgetWidth] = useState<number>(0);
    const { dailyWagersInfo,getFinancialDaily, dailyWagersFilter, dailyWagers, loaders: {isFinancialDailyLoading}} = useGeneralInfo()
    const [justMounted, setJustMounted] = useState(true)
    const { user } = useUser()
    const { languagePack: {pack: {generalInfo: lang}}} = useLanguage()
    const { xxSmallScreen: xs } = useCommonMediaQueries()

    const count = 2
    const itemWidth = `calc((100% - (${count - 1} * ${theme.spacing(1)})) / ${count})`

    useEffect(() => {
        if (user !== null && justMounted && !isFinancialDailyLoading && dailyWagers === null) {
            getFinancialDaily(dailyWagersFilter)
            setJustMounted(false)
        }
    }, [dailyWagers, dailyWagersFilter, getFinancialDaily, isFinancialDailyLoading, justMounted, user]);

    const groupedData = dailyWagers !== null ? Object.values(groupDataByDay(dailyWagers)) : [];

    const handleUsernameChange = (username: string | null) => {
        const newFilter = {...dailyWagersFilter, username}
        getFinancialDaily(newFilter)
    }

    const handleTimeChange = (fromTime: number | null, toTime: number | null) => {
        getFinancialDaily({ ...dailyWagersFilter, fromTime, toTime })
    }

    const CustomTooltip: FC<CustomTooltipProps> = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <Card elevation={2}>
                    <Stack padding={'15px'}>
                        <Stack direction={'row'}>
                            <Typography fontWeight={600}>{`Date: ${label}`}</Typography>
                        </Stack>
                        {payload.map(data => <Stack direction={'row'}><Typography
                            color={data.color}>{`${data.name}: ${data.dataKey === 'user_losses' || data.dataKey === 'user_wins' ? convertCentsToCurrencyString(data.value) : data.value}`}</Typography></Stack>)}
                    </Stack>
                </Card>
            );
        }

        return null;
    };

    return <Widget
        setWidgetWidth={setWidgetWidth}
        height={height}
        headerTitle={lang.financialDailyWidgetLabel}
        headerActions={<>
            <MiniButton
                onClick={() => getFinancialDaily(dailyWagersFilter)}
                icon={isFinancialDailyLoading ? <CircularProgress size={'18px'}/> : <AutorenewIcon/>}
                buttonVariant={'outlined'}
            />
        </>}
    >
        <Box display={'flex'} flexDirection={xs ? 'column' : 'row'} gap={1}>
            <Box flex={1} display={'flex'}>
                <UsernameFilter username={dailyWagersFilter.username} onUsernameChanged={handleUsernameChange}/>
            </Box>
            <Box flex={1} display={'flex'}>
                <TimeFilter
                    fromTime={dailyWagersFilter.fromTime}
                    toTime={dailyWagersFilter.toTime}
                    onTimeChanged={handleTimeChange}
                />
            </Box>
        </Box>
            <ResponsiveContainer width="100%" height={400}>
                <ComposedChart
                    data={groupedData}
                    margin={{
                        top: 20,
                        right: 2,
                        bottom: 20,
                        left: 2,
                    }}
                >
                    <CartesianGrid strokeDasharray="1 2"/>
                    <XAxis dataKey="calendar_day"/>
                    <YAxis yAxisId="left"
                           width={widgetWidth >= 700 ? 100 : 50}
                           stroke={"#3f3f3f"}
                           tick={{fontSize: widgetWidth >= 700 ? 14 : 10}}
                    />
                    <YAxis yAxisId="right" orientation="right" stroke={"#bf55fd"}
                           label={widgetWidth >= 700 ? lang.betsLabel : undefined}
                           width={widgetWidth >= 700 ? 100 : 50}/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Legend/>
                    <Bar name={lang.userBetsLabel} yAxisId="right" dataKey="user_bets" barSize={40} fill={"#bf55fd"}/>
                    <Line name={lang.userLossesLabel} yAxisId="left" type="monotone" dataKey="user_losses"
                          stroke={"#0054f1"} strokeWidth={3}/>
                    <Line name={lang.userWinsLabel} yAxisId="left" type="monotone" dataKey="user_wins"
                          stroke={"#f81500"} strokeWidth={3}/>
                </ComposedChart>
            </ResponsiveContainer>

            <Box display={'flex'} gap={1} flexWrap={'wrap'}>
                <Box width={itemWidth}>
                    <MoneySurfaceView
                        size={'small'}
                        variant={'outlined'}
                        title={lang.totalBetsLabel}
                        value={dailyWagersInfo.totalBets}
                    />
                </Box>
                <Box width={itemWidth}>
                    <MoneySurfaceView
                        size={'small'}
                        variant={'outlined'}
                        title={lang.totalGGRLabel}
                        value={convertCentsToCurrencyString(dailyWagersInfo.totalGGR)}
                    />
                </Box>
            </Box>
    </Widget>
}