import React from 'react';
import ReactDOM from 'react-dom/client';
import './app/themes/violet_dark/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./app/store";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ApiErrorDialog } from "./app/components/commons/apiErrorDialog/ApiErrorDialog";
import { SnackbarProvider } from "notistack";
import {theme} from "./app/themes/violet_dark/theme";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <SnackbarProvider maxSnack={5}>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <App/>
                    <ApiErrorDialog/>
                </ThemeProvider>
            </SnackbarProvider>
        </Provider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
