import { FC, useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { TimeFilter } from "./TimeFilter";
import { useLocation, useNavigate } from "react-router-dom";
import { convertUnixDateToString } from "../../../features/common_funcs";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from "@mui/material/IconButton";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { QuickDates } from "./QuickDates";

interface TimeFilterPanelProps {
    fromTime: number|null
    toTime: number|null
    onTimeChanged?: (fromTime: number|null, toTime: number|null) => void
    pullParamsToAddressBar?: boolean
}

export const TimeFilterPanel: FC<TimeFilterPanelProps> = ({fromTime, toTime, onTimeChanged= () => {}, pullParamsToAddressBar = false}) => {
    const { smallScreen } = useCommonMediaQueries()
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const { languagePack: {pack: {timeFilter: lang}}} = useLanguage()

    const [ fromTimeShow, setFromTimeShow ] = useState<boolean>(false)
    const [ toTimeShow, setToTimeShow ] = useState<boolean>(false)

    const changeAddressBarParams = (fromTime: number|null, toTime: number|null) => {
        if(pullParamsToAddressBar) {
            if (fromTime !== null) {
                searchParams.set('fromTime', fromTime.toString());
            } else searchParams.delete('fromTime')

            if (toTime !== null) {
                searchParams.set('toTime', toTime.toString());
            } else searchParams.delete('toTime')

            searchParams.delete('page')

            navigate({
                pathname: location.pathname,
                search: searchParams.toString(),
            });
        }
    }

    const handleSetFromTime = (time: number|null) => {
        onTimeChanged(time, toTime)
        changeAddressBarParams(time, toTime)
    }

    const handleSetToTime = (time: number|null) => {
        onTimeChanged(fromTime, time)
        changeAddressBarParams(fromTime, time)
    }

    // Template must contain %time% keyword
    const getLabel = (defaultLabel: string, labelTemplate: string, time: null|number): string => {
        if (time !== null) {
            return labelTemplate.replace('%time%', convertUnixDateToString(time))
        }else {
            return defaultLabel
        }
    }

    return <>
        <Stack display={'flex'} direction={smallScreen ? 'column' : 'row'} gap={'6px'} padding={'8px'} alignItems={'center'}>
            {!fromTimeShow ?
                <Box display={'flex'} alignItems={'center'}>
                    <AddTimeButton holdData={Boolean(fromTime)} label={getLabel(lang.fromTimeDefaultLabel, lang.fromTimeLabel, fromTime)}
                                   onClick={() => setFromTimeShow(true)}/>
                    {fromTime !== null && <IconButton color="warning" onClick={() => handleSetFromTime(null)}>
                        <HighlightOffIcon fontSize={'small'}/>
                    </IconButton>}</Box> :
                <TimeFilter dateTime={fromTime} setTime={handleSetFromTime} close={() => setFromTimeShow(false)}/>
            }
            {!toTimeShow ?
                <Box display={'flex'} alignItems={'center'}>
                <AddTimeButton holdData={Boolean(toTime)} label={getLabel(lang.toTimeDefaultLabel, lang.toTimeLabel, toTime)} onClick={() => setToTimeShow(true)}/>
                    {toTime !== null && <IconButton color="warning" onClick={() => handleSetToTime(null)}>
                        <HighlightOffIcon fontSize={'small'}/>
                    </IconButton>}</Box> :
                <TimeFilter dateTime={toTime} setTime={handleSetToTime} close={() => setToTimeShow(false)}/>
            }

            <QuickDates/>
        </Stack>
    </>
}


interface AddTimeButtonProps {
    label: string
    onClick?: () => void
    holdData: boolean
}

const AddTimeButton:FC<AddTimeButtonProps> = ({label, holdData, onClick = () => {}}) => {
    return <Button sx={{textWrap: 'nowrap'}} color={holdData ? 'secondary' : 'primary'} size={'small'} variant={'outlined'} onClick={onClick}>
        <Box display={'flex'} alignItems={'center'} columnGap={'4px'}>
        {label}
        <MoreTimeIcon/>
    </Box>
    </Button>
}