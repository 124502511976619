import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from "react";

const TelegramIcon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 24 24" xmlSpace="preserve">
                    <path d="M1.3,12.2L6.4,14l12.2-7.5c0.2-0.1,0.4,0.1,0.2,0.3l-9.3,8.5l-0.3,4.8c0,0.2,0.1,0.4,0.4,0.4c0.1,0,0.2,0,0.3-0.1l2.9-2.8
		l5.2,3.9c0.6,0.4,1.4,0.1,1.5-0.6l3.6-16.5c0.2-0.9-0.7-1.7-1.6-1.4L1.3,10.9C0.6,11.1,0.7,12,1.3,12.2z" fill="currentColor"/>
            </svg>
        </SvgIcon>
    );
}

export default TelegramIcon;