import { FC, useCallback, useEffect, useState } from "react";
import { ModalInterface } from "../../commons/modal_wrapper/model";
import { ModalWrapper } from "../../commons/modal_wrapper/ModalWrapper";
import { Button, CircularProgress, FormControlLabel, LinearProgress, Stack, Switch, TextField } from "@mui/material";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { usePlayersAgents } from "../../../features/manage_players_agents/usePlayersAgents";
import {User, UserRole} from "../../../features/user/user";
import { useSnackbar, VariantType } from "notistack";
import { useUser } from "../../../features/user/useUser";
import CurrencySwitcher from "../../manage_users/CurrencySwitcher";

interface CreatePlayerAgentProps extends ModalInterface {
    onDone?: () => void
    role: 'agent' | 'player'
    userIdForEdit?: number
}

export const CreatePlayerAgent: FC<CreatePlayerAgentProps> = ({ onDone = () => {}, role, userIdForEdit}) => {
    const [justMounted, setJustMounted] = useState(true)
    const { getOneUser, userRole, user: loggedUser } = useUser()
    const { enqueueSnackbar } = useSnackbar();
    const { languagePack: {pack: {createUser: createUserLang, userProfileForm: userProfileFormLang}}} = useLanguage()
    const { isCreateUserLoading, createUser, changeUserPassword, updateUserInfo } = usePlayersAgents()

    const [depositEnabled, setDepositEnabled] = useState(true)
    const [withdrawEnabled, setWithdrawEnabled] = useState(true)
    const [onlyPlayers, setOnlyPlayers] = useState(false)

    const [ newPasswordError, setNewPasswordError ] = useState('');
    const [ retypeNewPasswordError, setRetypeNewPasswordError ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ username, setUsername ] = useState('');
    const [ firstname, setFirstname ] = useState('');
    const [ lastname, setLastname ] = useState('');
    const [ newPassword, setNewPassword ] = useState<string | undefined>();
    const [ retypeNewPassword, setRetypeNewPassword ] = useState<string | undefined>();
    const [ userLoading, setUserLoading] = useState(false)
    const [passwordIsSaving, setPasswordIsSaving] = useState(false)
    const [userIsSaving, setUserIsSaving] = useState(false)
    const [selectedCurrency, setSelectedCurrency] = useState<string | undefined>(loggedUser?.user_currency)

    const [editedUser, setEditedUser] = useState<User | undefined>(undefined)
    
    const [onlyRequiredFields, setOnlyRequiredFields] = useState<boolean>(true)

    useEffect(() => {
        if (justMounted) {
            setJustMounted(false)
            if (userIdForEdit !== undefined) {
                getOneUser(userIdForEdit, setUserLoading, (user) => {
                    setUsername(user.user_name)
                    setFirstname(user.user_firstname ?? '')
                    setLastname(user.user_lastname ?? '')
                    setEmail(user.user_email ?? '')
                    setDepositEnabled(user.deposit_enabled)
                    setWithdrawEnabled(user.withdrawal_enabled)
                    setOnlyPlayers(user.only_players)
                    setEditedUser(user)
                })
            }
        }
    }, [getOneUser, justMounted, userIdForEdit])

    useEffect(() => {
        if (!Boolean(userIdForEdit)) {
            if ((newPassword !== undefined && newPassword.trim() === '') || (newPassword === undefined && retypeNewPassword)) {
                setNewPasswordError(userProfileFormLang.emptyFieldWarningMessage)
            } else {
                setNewPasswordError('')
            }
            if (((newPassword) && retypeNewPassword === undefined) || (retypeNewPassword !== undefined && retypeNewPassword.trim() === '')) {
                setRetypeNewPasswordError(userProfileFormLang.emptyFieldWarningMessage)
            } else if (newPassword && retypeNewPassword && newPassword !== retypeNewPassword) {
                setRetypeNewPasswordError(userProfileFormLang.passNotMatchWarningMessage)
            } else {
                setRetypeNewPasswordError('')
            }
        } else {
            if (Boolean(newPassword) || Boolean(retypeNewPassword)) {
                if ((newPassword !== undefined && newPassword.trim() === '') || (newPassword === undefined && retypeNewPassword)) {
                    setNewPasswordError(userProfileFormLang.emptyFieldWarningMessage)
                } else {
                    setNewPasswordError('')
                }
                if (((newPassword) && retypeNewPassword === undefined) || (retypeNewPassword !== undefined && retypeNewPassword.trim() === '')) {
                    setRetypeNewPasswordError(userProfileFormLang.emptyFieldWarningMessage)
                } else if (newPassword && retypeNewPassword && newPassword !== retypeNewPassword) {
                    setRetypeNewPasswordError(userProfileFormLang.passNotMatchWarningMessage)
                } else {
                    setRetypeNewPasswordError('')
                }
            } else {
                setNewPasswordError('')
                setRetypeNewPasswordError('')
            }
        }

    }, [newPassword, retypeNewPassword, userIdForEdit, userProfileFormLang])

    const sendSnackBar = useCallback((variant: VariantType, message: string) => {
        enqueueSnackbar(message, { variant });
    }, [enqueueSnackbar])

    const handleCreateUser = useCallback(() => {
        const user: User = {
            banned: editedUser?.banned ?? false,
            registration_time_unix: 0,
            user_balance: editedUser?.user_balance ?? 0,
            user_email: email,
            user_id: editedUser?.user_id ?? -1,
            user_name: username,
            user_currency: editedUser?.user_currency ?? selectedCurrency ?? 'ARS',
            user_firstname: firstname,
            user_lastname: lastname,
            user_role: role,
            deposit_rate: editedUser?.deposit_rate ?? 1.0,
            deposit_enabled: depositEnabled,
            withdrawal_enabled: withdrawEnabled,
            only_players: onlyPlayers
        }
        if (!Boolean(userIdForEdit)) {
            if (Boolean(username) && newPassword && Boolean(newPassword)) {
                createUser(user, newPassword, () => {
                    sendSnackBar('success', role === 'agent' ? createUserLang.agentSuccessfullyCreatedMessage : createUserLang.playerSuccessfullyCreatedMessage)
                    onDone()
                })
            }
        } else if (editedUser) {
            if (
                firstname !== editedUser.user_firstname
                || lastname !== editedUser.user_lastname
                || email !== editedUser.user_email
                || depositEnabled !== editedUser.deposit_enabled
                || withdrawEnabled !== editedUser.withdrawal_enabled
                || onlyPlayers !== editedUser.only_players
            ) {
                console.log('user info has changed')
                updateUserInfo(user, () => {
                    if (!passwordIsSaving) {
                        onDone()
                    }
                }, setUserIsSaving)
            } else console.log('no changes user info')
            if (newPassword && Boolean(newPassword) && Boolean(retypeNewPassword) && newPassword === retypeNewPassword) {
                changeUserPassword(editedUser.user_id, newPassword, () => {
                    if (!userIsSaving) {
                        onDone()
                    }
                }, setPasswordIsSaving)
            }
        }
    }, [changeUserPassword, createUser, createUserLang.agentSuccessfullyCreatedMessage, createUserLang.playerSuccessfullyCreatedMessage, depositEnabled, editedUser, email, firstname, lastname, newPassword, onDone, onlyPlayers, passwordIsSaving, retypeNewPassword, role, selectedCurrency, sendSnackBar, updateUserInfo, userIdForEdit, userIsSaving, username, withdrawEnabled])

    const isCreateButtonDisabled = useCallback(() => {
        let disabled = false
        if (!Boolean(userIdForEdit)) {
            if (!Boolean(username)) disabled = true
            if (!Boolean(newPassword)) disabled = true
            if (!Boolean(retypeNewPassword)) disabled = true
            if (newPasswordError) disabled = true
            if (retypeNewPasswordError) disabled = true
            if (isCreateUserLoading) disabled = true
        } else  {
            if (newPasswordError) disabled = true
            if (retypeNewPasswordError) disabled = true
            if (userLoading) disabled = true
        }
        return disabled
    }, [isCreateUserLoading, newPassword, newPasswordError, retypeNewPassword, retypeNewPasswordError, userIdForEdit, userLoading, username])

    return <Stack pt={'10px'} rowGap={'10px'}>
        {userLoading && <LinearProgress color={'primary'}/>}
        {(userRole === UserRole.PLATFORM || userRole === UserRole.OWNER) && <CurrencySwitcher onCurrencySelected={setSelectedCurrency} selectedCurrency={selectedCurrency}/>}
        <TextField
            required
            fullWidth
            disabled={isCreateUserLoading || Boolean(userIdForEdit) || userLoading}
            label={createUserLang.usernameLabel}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
            required
            fullWidth
            disabled={isCreateUserLoading || userLoading}
            label={userProfileFormLang.newPassFieldLabel}
            type={'password'}
            value={Boolean(newPassword) ? newPassword : ''}
            error={Boolean(newPasswordError)}
            helperText={newPasswordError}
            onChange={(e) => setNewPassword(e.target.value)}
        />
        <TextField
            required
            fullWidth
            disabled={isCreateUserLoading || userLoading}
            label={userProfileFormLang.retypePassFieldLabel}
            type={'password'}
            value={Boolean(retypeNewPassword) ? retypeNewPassword : ''}
            error={Boolean(retypeNewPasswordError)}
            helperText={retypeNewPasswordError}
            onChange={(e) => setRetypeNewPassword(e.target.value)}
        />
        {!onlyRequiredFields && <>
        <TextField
            fullWidth
            disabled={isCreateUserLoading || userLoading}
            label={userProfileFormLang.firstnameFieldLabel}
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
        />
        <TextField
            fullWidth
            disabled={isCreateUserLoading || userLoading}
            label={userProfileFormLang.lastnameFieldLabel}
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
        />
        <TextField
            fullWidth
            disabled={isCreateUserLoading || userLoading}
            label={userProfileFormLang.emailFieldLabel}
            type={'email'}
            value={email}
            onChange={(e) => {
                setEmail(e.target.value)
            }}
        />
        </>}
        <FormControlLabel
            control={<Switch checked={onlyRequiredFields} onChange={e => setOnlyRequiredFields(e.target.checked)} />}
            label={userProfileFormLang.onlyRequiredFieldsLabel}
        />

        {role === 'agent' && <>
            <FormControlLabel
                disabled={isCreateUserLoading || userLoading}
                control={<Switch checked={depositEnabled} onChange={e => setDepositEnabled(e.target.checked)}/>}
                label={createUserLang.depositEnabledSwitcherLabel}
            />
            <FormControlLabel
                disabled={isCreateUserLoading || userLoading}
                control={<Switch checked={withdrawEnabled} onChange={e => setWithdrawEnabled(e.target.checked)} />}
                label={createUserLang.withdrawEnabledSwitcherLabel}
            />
            <FormControlLabel
                disabled={isCreateUserLoading || userLoading}
                control={<Switch checked={onlyPlayers} onChange={e => setOnlyPlayers(e.target.checked)} />}
                label={createUserLang.onlyPlayersSwitcherLabel}
            />
        </>}
        <Button disabled={isCreateButtonDisabled() || passwordIsSaving || userIsSaving} variant={'contained'}
                endIcon={isCreateUserLoading || userLoading || passwordIsSaving || userIsSaving ? <CircularProgress size={16}/> : <></>}
                onClick={handleCreateUser}>
            {userIdForEdit !== undefined ? (role === 'agent' ? createUserLang.editAgentBTN : createUserLang.editPlayerBTN)
                :
                (role === 'agent' ? createUserLang.createAgentBTN : createUserLang.createPlayerBTN)}
        </Button>
    </Stack>
}

export const CreatePlayerAgentModal: FC<CreatePlayerAgentProps> = (props) => <ModalWrapper component={CreatePlayerAgent} componentProps={props}/>