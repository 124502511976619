import React, { FC, useState } from "react";
import s from './SideMenu.module.css'
import {Box, ButtonBase} from "@mui/material";
import logo from '../../../../assets/Logo.webp'
import { SideMenuItem } from "./SideMenuItem";
import {Link, useLocation} from "react-router-dom";
import { SideMenuDivider } from "./SideMenuDivider";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CasinoIcon from '@mui/icons-material/Casino';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { ConfirmationDialog } from "../../commons/ConfirmationDialog";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { useUser } from "../../../features/user/useUser";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import InfoIcon from '@mui/icons-material/Info';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import { ProviderAccess } from "../../../features/providers/models/access";
import { UserRole } from "../../../features/user/user";
import { UserTransfersAccess } from "../../../features/user_transfers/models/access";
import LanguageSelector from "../../commons/lang/LanguageSelector";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {Person as AgentIcon, PersonAdd as AddAgentIcon, PersonOutline as PlayerIcon, PersonAddAlt as AddPlayerIcon } from '@mui/icons-material/';
import { CreatePlayerAgentModal } from "../../players_n_agents/players_&_agents_creation/CreatePlayerAgent";
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import {TelegramIcon} from "../../../themes/theme_connector";

interface SideMenuProps {
    close?: () => void
}

export const SideMenu: FC<SideMenuProps> = ({close = () => {}}) => {
    const [ showLogout, setShowLogout ] = useState<boolean>(false)
    const { token, userRole, logOut } = useUser()
    const { languagePack: { pack: lang } } = useLanguage()
    const location = useLocation();
    const { xxSmallScreen } = useCommonMediaQueries()
    const [isCreateUserOpened, setIsCreateUserOpened] = useState<'agent' | 'player' | null>(null)

    const isActivePath = (paths: string | string[]): boolean => {
        const normalizedPaths = Array.isArray(paths) ? paths.map(path => path.replace(/\/$/, '')) : [paths.replace(/\/$/, '')];
        return normalizedPaths.includes(location.pathname.replace(/\/$/, ''));
    };

    return <Box
        width={'300px'}
        display={'flex'}
        flexDirection={'column'}
        pr={'30px'}
        rowGap={'10px'}
    >
        <Box
            minHeight={'64px'}
            px={3}
            display={'flex'}
            alignItems={'center'}
            gap={1.5}
            justifyContent={'space-between'}
        >
            <img alt={'Salta la Banca logo'} src={logo} height={30}/>
            <Link to={'https://t.me/saltalabancagraficas'} target={'_blank'}>
                <ButtonBase
                    sx={{
                        borderRadius: '8px',
                        backgroundColor: '#24A1DE',
                        width: '32px',
                        height: '32px',
                        '&:hover': {
                            backgroundColor: '#46b4ea'
                        }
                    }}
                >
                    <TelegramIcon/>
                </ButtonBase>
            </Link>
        </Box>

        {xxSmallScreen && <Box px={'20px'}><LanguageSelector fullWidth/></Box>}

        {token && <div className={s.menuItemsSection}>
            <SideMenuItem onClick={close} icon={<SpaceDashboardIcon/>} title={lang.sideMenu.dashboard} link={"/"}
                          active={isActivePath([  "/", "/dashboard/" ])}/>
            <SideMenuItem onClick={close} icon={<PeopleAltIcon/>} title={lang.sideMenu.users} link={"/users"} active={isActivePath(["/users"])}/>
            <SideMenuItem onClick={close} icon={<AgentIcon/>} title={lang.playersAgents.agentsTab} link={"/users?userRole=agent"}/>
            <SideMenuItem onClick={close} icon={<PlayerIcon/>} title={lang.playersAgents.playersTab} link={"/users?userRole=player"}/>
            <SideMenuItem onClick={() => {
                setIsCreateUserOpened('agent')

            }} icon={<AddAgentIcon/>} title={lang.createUser.createAgentBTN}/>
            <SideMenuItem onClick={() => {
                setIsCreateUserOpened('player')

            }} icon={<AddPlayerIcon/>} title={lang.createUser.createPlayerBTN}/>
            <SideMenuDivider/>

            <SideMenuItem onClick={close} icon={<CasinoIcon/>} title={lang.sideMenu.gameHistory} link={"/game_history"}
                          active={isActivePath("/game_history")}/>
            <SideMenuDivider>{lang.sideMenu.netwinDivider}</SideMenuDivider>
            <SideMenuItem onClick={close} icon={<AssignmentIndIcon/>} title={lang.sideMenu.netwinUser} link={"/netwin/agents"}
                          active={isActivePath("/netwin/agents")}/>
            <SideMenuItem onClick={close} icon={<InfoIcon/>} title={lang.sideMenu.netwinGeneralInfo} link={"/netwin/general_info"}
                          active={isActivePath("/netwin/general_info")}/>
            {
                userRole !== null && <>

                    {[UserRole.OWNER, UserRole.AGENT].includes(userRole) &&
                        <SideMenuDivider/>
                    }
                    {/*{AgentTransfersAccess.includes(userRole) && <SideMenuItem onClick={close} icon={<CurrencyExchangeIcon/>} title={lang.sideMenu.agentTransfers}*/}
                    {/*               link={"/transfers/agent"}*/}
                    {/*               active={isActivePath("/transfers/agent")}/>}*/}
                    {UserTransfersAccess.includes(userRole) && <SideMenuItem onClick={close} icon={<CurrencyExchangeIcon/>} title={lang.sideMenu.depositsAndWithdrawals}
                                   link={"/transfers/user"}
                                   active={isActivePath("/transfers/user")}/>}

                    {[UserRole.PLATFORM].includes(userRole) &&
                        <SideMenuDivider/>
                    }
                    {ProviderAccess.includes(userRole) && <SideMenuItem
                        onClick={close}
                        icon={<SportsEsportsIcon/>}
                        title={lang.sideMenu.providers}
                        link={"/providers/"}
                        active={isActivePath("/providers/")}
                    />}
                </>
            }
            <SideMenuDivider/>
            <SideMenuItem onClick={() => {
                setShowLogout(true)
            }} icon={<ExitToAppIcon/>} title={lang.sideMenu.logOutBtn}/>
        </div>}


        {showLogout && <ConfirmationDialog close={() => {
            setShowLogout(false)
        }} message={lang.common.logOutConfirmationMessage} onConfirm={() => {
            logOut()
        }}/>}
        {isCreateUserOpened !== null && isCreateUserOpened === 'agent' &&
            <CreatePlayerAgentModal dialogTitle={lang.createUser.agentCreationTitle} role={'agent'}
                                    close={() => {
                                        setIsCreateUserOpened(null)
                                        // close()
                                    }}/>}

        {isCreateUserOpened !== null && isCreateUserOpened === 'player' &&
            <CreatePlayerAgentModal dialogTitle={lang.createUser.playerCreationTitle} role={'player'}
                                    close={() => {
                                        setIsCreateUserOpened(null)
                                        // close()
                                    }}/>}
    </Box>
}