import { FC } from "react";
import { Button } from "@mui/material";
import { useQuery } from "../../../features/common_funcs/query_hook/useQuery";
import { useLanguage } from "../../../features/localisation/useLanguage";

const getUnixTime = (date: Date): number => Math.floor(date.getTime() / 1000);

const getToday = () => {
    const now = new Date();
    const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
    return { timeFrom: getUnixTime(startOfDay), toTime: getUnixTime(endOfDay) };
};

const getYesterday = () => {
    const now = new Date();
    const startOfYesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
    const endOfYesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 23, 59, 59);
    return { timeFrom: getUnixTime(startOfYesterday), toTime: getUnixTime(endOfYesterday) };
};

const getCurrentWeek = () => {
    const now = new Date();
    const dayOfWeek = now.getDay() || 7; // 1 (Monday) to 7 (Sunday), treating Sunday as 7
    const startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() - dayOfWeek + 1); // Start from Monday
    startOfWeek.setHours(0, 0, 0, 0);

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // End on Sunday
    endOfWeek.setHours(23, 59, 59, 999);

    return { timeFrom: getUnixTime(startOfWeek), toTime: getUnixTime(endOfWeek) };
};

const getCurrentMonth = () => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);
    return { timeFrom: getUnixTime(startOfMonth), toTime: getUnixTime(endOfMonth) };
};

export const QuickDates: FC = () => {
    const {languagePack: {pack: {timeFilter: lang}}} = useLanguage()
    const {batchUpdateQueryParameters} = useQuery()

    const handleToday = () => {
        const { timeFrom, toTime } = getToday();
        batchUpdateQueryParameters([
            {paramName: 'fromTime', value: timeFrom.toString()},
            {paramName: 'toTime', value: toTime.toString()}
        ])
        console.log('Today:', timeFrom, toTime);
    };

    const handleYesterday = () => {
        const { timeFrom, toTime } = getYesterday();
        batchUpdateQueryParameters([
            {paramName: 'fromTime', value: timeFrom.toString()},
            {paramName: 'toTime', value: toTime.toString()}
        ])
        console.log('Yesterday:', timeFrom, toTime);
    };

    const handleCurrentWeek = () => {
        const { timeFrom, toTime } = getCurrentWeek();
        batchUpdateQueryParameters([
            {paramName: 'fromTime', value: timeFrom.toString()},
            {paramName: 'toTime', value: toTime.toString()}
        ])
        console.log('Week:', timeFrom, toTime);
    };

    const handleCurrentMonth = () => {
        const { timeFrom, toTime } = getCurrentMonth();
        batchUpdateQueryParameters([
            {paramName: 'fromTime', value: timeFrom.toString()},
            {paramName: 'toTime', value: toTime.toString()}
        ])
        console.log('Month:', timeFrom, toTime);
    };

    return <>
        <Button color={'secondary'} onClick={handleToday}>
            {lang.todayBTN}
        </Button>
        <Button color={'secondary'} onClick={handleYesterday}>
            {lang.yesterdayBTN}
        </Button>
        <Button color={'secondary'} onClick={handleCurrentWeek}>
            {lang.weekBTN}
        </Button>
        <Button color={'secondary'} onClick={handleCurrentMonth}>
            {lang.monthBTN}
        </Button>
    </>
}
