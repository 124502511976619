import { UserTransfersState } from "./models/state";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderAction } from "../common_models";
import { UserTransfersLoaders } from "./models/loaders";
import {UserTransfersInfo} from "./models/models";

const initialState: UserTransfersState = {
    userTransfers: null,
    filter: {
        fromTime: null,
        toTime: null,
        username:  null,
        userRole: null,
        typeTransaction: null,
        direct: null
    },
    usersTransfersInfo: {
      totalDeposits: null,
      totalWithdraws: null
    },
    pageSize: 30,
    currentPage: 1,
    totalRecords: 0,
    totalPages: 0,
    isUserTransfersLoading: false
}

export const UserTransfersSlice = createSlice({
    name: 'userTransfers',
    initialState,
    reducers: {
       setUserTransfersLoader: (state, action: PayloadAction<LoaderAction<UserTransfersLoaders>>) => {
           switch (action.payload.loader) {
               case UserTransfersLoaders.isUserTransfersLoading:
                   state.isUserTransfersLoading = action.payload.isLoading
                   break
           }
       },
        resetUserTransfers: (state) => {
            state.userTransfers = null
            state.totalRecords = 0
            state.totalPages = 0
            state.currentPage = 1
            state.pageSize = 30
            state.filter = {
                fromTime: null,
                toTime: null,
                username: null,
                userRole: null,
                typeTransaction: null,
                direct: null
            }
            state.usersTransfersInfo = {
                totalDeposits: null,
                totalWithdraws: null
            }
            state.isUserTransfersLoading = false
        },
        setUserTransfersTotalRecords: (state, action: PayloadAction<number>) => {
            state.totalRecords = action.payload
            state.totalPages = Math.ceil(action.payload / state.pageSize)
        },
        setUserTransfersCurrentPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload
        },
        setUserTransfersItemsPerPage: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload
        },
        setUserTransfers: (state, action: PayloadAction<any>) => {
            state.userTransfers = action.payload
        },
        setUserTransfersFilter: (state, action: PayloadAction<any>) => {
            state.filter = action.payload
        },
        setUsersTransfersInfo: (state, action: PayloadAction<UserTransfersInfo>) => {
           state.usersTransfersInfo = action.payload
        }
    }
})

export const {
    setUserTransfersLoader,
    resetUserTransfers,
    setUserTransfersCurrentPage,
    setUserTransfersItemsPerPage,
    setUserTransfersTotalRecords,
    setUserTransfersFilter,
    setUserTransfers,
    setUsersTransfersInfo
} = UserTransfersSlice.actions