import {iUseGeneralInfo} from "./models/hookInterface";
import {useAppDispatch, useAppSelector} from "../../store";
import {useLanguage} from "../localisation/useLanguage";
import {useUser} from "../user/useUser";
import Config, {getApiHost} from "../../config";
import {useCallback} from "react";
import {DailyUsersFilter, DailyWagersFilter} from "./models/filter";
import {
    setActiveUsers,
    setDailyActiveUsers,
    setDailyUsers,
    setDailyUsersFilter, setDailyUsersInfo,
    setDailyWagers,
    setDailyWagersFilter, setDailyWagersInfo, setGeneralStatistics,
    setLoader, setRevshare
} from "./GeneralInfoSlice";
import { GeneralInfoLoaders } from "./models/loaders";
import axios, {AxiosError} from "axios";
import {getTimeZoneOffset} from "../common_funcs";
import {ShowFinancialDailyResponse, ShowGeneralStatisticsResponse, ShowUsersDailyResponse} from "./models/responses";

export const useGeneralInfo = (): iUseGeneralInfo => {
    const dispatch = useAppDispatch();
    const { setLocalizedError, handleNetworkErrors } = useLanguage();
    const state = useAppSelector(state => state.generalInfo);
    const { token, user } = useUser();
    const apiURL = getApiHost()

    const getFinancialDaily = useCallback((filter: DailyWagersFilter) => {
        let filterForSending = state.dailyWagersFilter
        if (filter.toTime !== state.dailyWagersFilter.toTime
            || filter.fromTime !== state.dailyWagersFilter.fromTime
            || filter.username !== state.dailyWagersFilter.username) {
            dispatch(setDailyWagersFilter(filter))
            filterForSending = filter
        }

        if (token && user && apiURL) {
            dispatch(setLoader({
                loader: GeneralInfoLoaders.isFinancialDailyLoading,
                isLoading: true
            }))

            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ShowFinancialDaily')
            data.append('token', token)

            const timedelta = getTimeZoneOffset()
            data.append('timedelta', timedelta.toString())
            if (filterForSending.fromTime !== null) {
                data.append('fromtime', filterForSending.fromTime.toString())
            }
            if (filterForSending.toTime !== null) {
                data.append('totime', filterForSending.toTime.toString())
            }
            if (filterForSending.username !== null) {
                data.append('username', filterForSending.username)
            }

            axios
                .post<ShowFinancialDailyResponse>(apiURL, data)
                .then(response => {
                    const { success, error, data } = response.data
                    if (success && data) {
                        dispatch(setDailyWagers(data))
                        dispatch(setDailyWagersInfo(data.reduce(
                            (acc, curr) => {
                                acc.totalBets += curr.user_bets;
                                acc.totalGGR += curr.ggr;
                                return acc;
                            },
                            { totalBets: 0, totalGGR: 0 }
                        )))
                    } else if (error) {
                        dispatch(setDailyWagers([]))
                        if (error.code !== 2) {
                            setLocalizedError(error)
                        }
                    }
                })
                .catch((error: Error | AxiosError) => {
                    dispatch(setDailyWagers([]))
                    handleNetworkErrors(error)
                })
                .finally(() => {
                    dispatch(setLoader({
                        loader: GeneralInfoLoaders.isFinancialDailyLoading,
                        isLoading: false
                    }))
                })
            
        }
    }, [apiURL, dispatch, handleNetworkErrors, setLocalizedError, state.dailyWagersFilter, token, user])

    const getUsersDaily = useCallback((filter: DailyUsersFilter) => {
        let filterForSending = state.dailyUsersFilter
        if (filter.toTime !== state.dailyUsersFilter.toTime
            || filter.fromTime !== state.dailyUsersFilter.fromTime
            || filter.username !== state.dailyUsersFilter.username) {
            dispatch(setDailyUsersFilter(filter))
            filterForSending = filter
        }

        if (token && user && apiURL) {
            dispatch(setLoader({
                loader: GeneralInfoLoaders.isDailyUsersLoading,
                isLoading: true
            }))

            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ShowUsersDaily')
            data.append('token', token)

            const timedelta = getTimeZoneOffset()
            data.append('timedelta', timedelta.toString())
            if (filterForSending.fromTime !== null) {
                data.append('fromtime', filterForSending.fromTime.toString())
            }
            if (filterForSending.toTime !== null) {
                data.append('totime', filterForSending.toTime.toString())
            }
            if (filterForSending.username !== null) {
                data.append('username', filterForSending.username)
            }

            axios
                .post<ShowUsersDailyResponse>(apiURL, data)
                .then(response => {
                    const {success, error, new_users, active_users, active_users_total} = response.data
                    if (success) {
                        new_users?.forEach(user => {
                            user.date = new Date(user.calendar_day)
                        })
                        active_users?.forEach(user => {
                            user.date = new Date(user.calendar_day)
                        })
                        dispatch(setDailyUsers(new_users ?? []))
                        dispatch(setDailyActiveUsers(active_users ?? []))
                        dispatch(setActiveUsers(active_users_total ?? 0))
                        const totalNewUsers = new_users?.reduce((acc, curr) => {
                            return acc + curr.new_players
                        }, 0)
                        // const totalActiveUsers = active_users?.reduce((acc, curr) => {
                        //     return acc + curr.active_players
                        // }, 0)
                        dispatch(setDailyUsersInfo({ totalActiveUsers: active_users_total ?? 0, totalNewUsers : totalNewUsers ?? 0 }))
                    } else if (error) {
                        dispatch(setDailyUsers([]))
                        dispatch(setDailyActiveUsers([]))
                        dispatch(setActiveUsers(0))
                        if (error.code !== 2) {
                            setLocalizedError(error)
                        }
                    }
                })
                .catch((error: Error | AxiosError) => {
                    dispatch(setDailyUsers([]))
                    dispatch(setDailyActiveUsers([]))
                    dispatch(setActiveUsers(0))
                    handleNetworkErrors(error)
                })
                .finally(() => {
                    dispatch(setLoader({
                        loader: GeneralInfoLoaders.isDailyUsersLoading,
                        isLoading: false
                    }))
                })
        }
    }, [apiURL, dispatch, handleNetworkErrors, setLocalizedError, state.dailyUsersFilter, token, user])

    const getGeneralStatistics = useCallback((username?: string) => {
        if (token && user && apiURL) {
            dispatch(setLoader({
                loader: GeneralInfoLoaders.isGeneralStatisticsLoading,
                isLoading: true
            }))

            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ShowGeneralStatistics')
            data.append('token', token)

            if (username !== undefined) {
                data.append('username', username)
            }

            axios
                .post<ShowGeneralStatisticsResponse>(apiURL, data)
                .then(response => {
                    const {success, error, data, revshare_USD} = response.data
                    if (success) {
                        dispatch(setGeneralStatistics(data ?? []))
                        dispatch(setRevshare(revshare_USD ?? null))
                    } else if (error) {
                        dispatch(setGeneralStatistics([]))
                        dispatch(setRevshare(null))
                        if (error.code !== 2) {
                            setLocalizedError(error)
                        }
                    }
                })
                .catch((error: Error | AxiosError) => {
                    dispatch(setGeneralStatistics([]))
                    dispatch(setRevshare(null))
                    handleNetworkErrors(error)
                })
                .finally(() => {
                    dispatch(setLoader({
                        loader: GeneralInfoLoaders.isGeneralStatisticsLoading,
                        isLoading: false
                    }))
                })
        }
    }, [apiURL, dispatch, handleNetworkErrors, setLocalizedError, token, user])

    return {
        ...state,
        getFinancialDaily,
        getUsersDaily,
        getGeneralStatistics
    }
}