import { FC } from "react";
import { Box, Card, Stack, Typography } from "@mui/material";
import { useLanguage } from "../../features/localisation/useLanguage";
import Lock from "../../../assets/clipart/lock3.webp";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";

export const MustBeLogged: FC = () => {
    const { languagePack: { pack: { common: lang } } } = useLanguage()
    const { xxSmallScreen } = useCommonMediaQueries()

    return xxSmallScreen ? <Stack
        height={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
        // sx={{backgroundColor: 'white'}}
        padding={'6vw'}
    >
        <Typography sx={{userSelect: 'none'}} variant={'h1'} fontSize={'6vw'} textAlign={'center'} textTransform={'uppercase'}
                    fontWeight={600}>
            {lang.youMustBeLoggedMessage}
        </Typography>
        <Box
            height={'50vh'}
            width={'50vh'}
            sx={{
                backgroundImage: `url('${Lock}')`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'contain',
            }}
        />
    </Stack> : <Stack height={'100%'} pb={'10px'} justifyContent={'center'} alignItems={'center'}>
        <Card sx={{ px: '60px', py: '40px' }}>
            <Stack justifyContent={'center'} alignItems={'center'} height={'100%'}>
                <Box width={'50vh'}>
                    <Typography sx={{userSelect: 'none'}} variant={'h1'} fontSize={'4vh'} textAlign={'center'} textTransform={'uppercase'}
                                fontWeight={600}>
                        {lang.youMustBeLoggedMessage}
                    </Typography>
                </Box>

                <Box
                    height={'50vh'}
                    width={'50vh'}
                    sx={{
                        backgroundImage: `url('${Lock}')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                    }}
                />
            </Stack>
        </Card>
    </Stack>
}