import React, {FC, ReactNode, useEffect} from "react";
import {Widget} from "../../commons/widget_layout/Widget";
import {useAppDispatch} from "../../../store";
import {useGeneralInfo} from "../../../features/general_info/useGeneralInfo";
import {useLanguage} from "../../../features/localisation/useLanguage";
import {useUser} from "../../../features/user/useUser";
import {filterCounters} from "../../../features/general_info/funcs";
import {Box, CircularProgress, Skeleton, SvgIconProps, Typography, useTheme} from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Stack from "@mui/material/Stack";
import {useCommonMediaQueries} from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import SlotCounter from "react-slot-counter";
import {AgentIcon, BetshopIcon, CashierIcon, MiniButton, PlayerIcon} from "../../../themes/theme_connector";
import {UsernameFilter} from "../../commons/username_filter/UsernameFilter";

export const GeneralStatisticsWidget: FC = () => {
    const { xxSmallScreen: xs } = useCommonMediaQueries()
    const dispatch = useAppDispatch()
    const { smallScreen } = useCommonMediaQueries()
    const theme = useTheme()
    const { loaders: {isGeneralStatisticsLoading}, getGeneralStatistics, generalStatistics} = useGeneralInfo()
    const [username, setUsername] = React.useState<string | null>(null)
    const [justMounted, setJustMounted] = React.useState(true)
    const { languagePack: {pack: {generalInfo: {counterWidget: rolesLang, ...lang}}}} = useLanguage()
    const gap = theme.spacing(1)

    const { userRole, user } = useUser()

    const filtered = userRole !== null && generalStatistics !== null ? filterCounters(generalStatistics, userRole) : []

    const count = smallScreen ? 2 : filtered.length
    const itemWidth = `calc((100% - (${count - 1} * ${gap})) / ${count})`

    useEffect(() => {
        if (user !== null && justMounted && !isGeneralStatisticsLoading && generalStatistics === null) {
            getGeneralStatistics(undefined)
            setJustMounted(false)
        }
    }, [dispatch, generalStatistics, getGeneralStatistics, isGeneralStatisticsLoading, justMounted, user]);

    const getLocalization = (role: string) => {
        switch (role) {
            case 'agent':
                return rolesLang.agents
            case 'betshop':
                return rolesLang.betshops
            case 'cashier':
                return rolesLang.cashiers
            case 'player':
                return rolesLang.players
            default:
                return role
        }
    }

    type AllowedRoles = 'agent' | 'betshop' | 'cashier' | 'player'

    const getIcon = (role: AllowedRoles): React.ElementType<SvgIconProps> => {
        switch (role) {
            case 'agent':
                return AgentIcon
            case 'betshop':
                return BetshopIcon
            case 'cashier':
                return CashierIcon
            case 'player':
                return PlayerIcon
        }
    }


    interface IconRenderProps {
        icon: React.ElementType<SvgIconProps>
    }

    const IconRender: FC<IconRenderProps> = ({icon: Icon}): ReactNode => {
        return <Icon sx={{fontSize: '40px'}} color={'primary'}/>
    }

    return <Widget
        headerTitle={lang.usersWidgetLabel}
        headerActions={<>
            {!xs && <UsernameFilter username={username} onUsernameChanged={nameFilter => {
                setUsername(nameFilter)
                getGeneralStatistics(nameFilter ?? undefined)
            }}/>}

            {/* --------------------- */}

            <MiniButton
                onClick={() => getGeneralStatistics(username ?? undefined)}
                icon={isGeneralStatisticsLoading ? <CircularProgress size={'18px'}/> : <AutorenewIcon/>}
                buttonVariant={'outlined'}
            />
        </>}
    >
            {xs && <UsernameFilter username={username} onUsernameChanged={nameFilter => {
                setUsername(nameFilter)
                getGeneralStatistics(nameFilter ?? undefined)
            }}/>}
            <Box display={'flex'} rowGap={2} columnGap={1} flexWrap={'wrap'}>
                {
                    !isGeneralStatisticsLoading ? filtered.map(counter => <Box width={itemWidth} key={`counter-${counter.user_role}`}>
                            <Stack gap={1} px={2} alignItems={smallScreen ? 'center' : 'start'}>
                                {['agent', 'betshop', 'cashier', 'player'].includes(counter.user_role) && <IconRender icon={getIcon(counter.user_role as AllowedRoles)}/>}
                                <Stack alignItems={smallScreen ? 'center' : 'start'}>
                                    <Typography fontSize={14} fontWeight={600} lineHeight={1}>
                                        {getLocalization(counter.user_role)}
                                    </Typography>
                                    <Typography fontSize={26} fontWeight={600} lineHeight={1}>
                                        <SlotCounter value={counter.users} />
                                    </Typography>
                                </Stack>
                            </Stack>

                        </Box>) :
                        [1, 2, 3,4].map((i : number) => <CounterSkeleton itemWidth={`calc((100% - (${(smallScreen ? 2 : 4) - 1} * ${gap})) / ${smallScreen ? 2 : 4})`} key={`counter-skeleton-${i}`} smallScreen={smallScreen}/>)
                }
            </Box>


    </Widget>
}

interface CounterSkeletonProps {
    smallScreen: boolean
    itemWidth: string
}

const CounterSkeleton: FC<CounterSkeletonProps> = ({smallScreen, itemWidth}) => <Box width={itemWidth}>
    <Stack gap={1} px={2} alignItems={smallScreen ? 'center' : 'start'}>
    <Skeleton variant={'circular'} width={'40px'} height={'40px'}/>
    <Stack alignItems={smallScreen ? 'center' : 'start'}>
        <Typography fontSize={14} fontWeight={600} lineHeight={1}>
            <Skeleton variant={'text'} width={'70px'} animation={'wave'}/>
        </Typography>
        <Typography fontSize={26} fontWeight={600} lineHeight={1}>
            <Skeleton variant={'text'} width={'50px'}/>
        </Typography>
    </Stack>
</Stack>
</Box>