import { FC, ReactNode, useState } from "react";
import { Button, Stack } from "@mui/material";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import Drawer from "@mui/material/Drawer";
import { SortList } from "../../commons/SortList";
import { useLanguage } from "../../../features/localisation/useLanguage";

export interface SortingListItem {
    title: string
    orderBy: string
    sortDirection: string
}

interface FilterPanelProps {
    children?: ReactNode
    sortingList?: SortingListItem[]
    currentOrderBy?: string
    currentSortDirection?: string
    mobileUnderFilterPanelZone?: ReactNode
}

export const FilterPanel: FC<FilterPanelProps> = ({children, sortingList, currentOrderBy, currentSortDirection, mobileUnderFilterPanelZone }) => {
    const { languagePack: {pack: {common: lang}}} = useLanguage()
    const { smallScreen } = useCommonMediaQueries()
    const [drawerIsOpened, setDrawerIsOpened] = useState<boolean>(false)

    return !smallScreen ? <Stack
        width={'100%'}
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        pr={'10px'}
        sx={{
            overflowY: 'visible'
        }}
    >
        {children}
    </Stack> : <>
        <Stack direction={'row'} px={'10px'} columnGap={'4px'}>
            <Button variant={'contained'} fullWidth onClick={() => setDrawerIsOpened(true)}>
                {lang.optionsButton}
            </Button>
            {sortingList && <SortList sortingList={sortingList} currentOrderBy={currentOrderBy} currentSortDirection={currentSortDirection}/>}
        </Stack>

        {mobileUnderFilterPanelZone && <Stack direction={'row'} pt={'10px'} px={'10px'} columnGap={'4px'}>
            {mobileUnderFilterPanelZone}
        </Stack>}

        {smallScreen && <Drawer
            anchor="top"
            open={drawerIsOpened}
            onClose={() => setDrawerIsOpened(false)}>
            <Stack minWidth={'300px'} alignItems={'center'} padding={'10px'} maxHeight={'80dvh'}>
                {children}
            </Stack>
        </Drawer>}
    </>
}

interface FilterPanelSideProps {
    children?: ReactNode
}

export const FilterPanelSide: FC<FilterPanelSideProps> = ({children}) => {
    const { smallScreen } = useCommonMediaQueries()

    return <Stack direction={smallScreen ? 'column' : 'row'} minHeight={'44px !important'} gap={'10px'} width={smallScreen ? '100%' : undefined}>
        {children}
    </Stack>
}