import { useLocation } from "react-router-dom";
import { useAgents } from "../agents/useAgents";
import { useEffect, useRef } from "react";
import { useUserTransfers } from "./useUserTransfers";
import { getNumberParam } from "../common_funcs";
import { UserTransfersFilter } from "./models/filter";
import { useAccess } from "../common_funcs/access_hook/useAccess";
import { UserTransfersAccess } from "./models/access";

export const useUserTransfersWatcher = (disabled: boolean = false) => {
    const { hasAccess } = useAccess(UserTransfersAccess)
    const location = useLocation()
    const {selectedAgent} = useAgents()
    const prevSelectedAgent = useRef(selectedAgent)
    const { isUserTransfersLoading, getUserTransfersByAgent, userTransfers, filter, pageSize, currentPage } = useUserTransfers()

    useEffect(() => {
        if (!isUserTransfersLoading && !disabled && hasAccess) {
            const searchParams = new URLSearchParams(location.search)
            const qPage = getNumberParam(searchParams.get('page'))
            const qPageSize = getNumberParam(searchParams.get('pageSize'))
            const qFromTime = getNumberParam(searchParams.get('fromTime'))
            const qToTime = getNumberParam(searchParams.get('toTime'))
            const qUsername = searchParams.get('username')
            const typeTransactionValue = searchParams.get('typeTransaction')
            const qTypeTransaction: 'deposit' | 'withdraw' | null = (typeTransactionValue === 'deposit' || typeTransactionValue === 'withdraw') ? typeTransactionValue : null
            const userRoleValue = searchParams.get('userRole')
            const qUserRole: 'player' | 'agent' | null = (userRoleValue === 'player' || userRoleValue === 'agent') ? userRoleValue : null
            const directValue = searchParams.get('direct')
            const qDirect: boolean | null = (directValue === 'true') ? true : (directValue === 'false') ? false : null

            const pageHasChanges = qPage !== null && qPage !== currentPage
            const pageSizeHasChanges = qPageSize !== null && qPageSize !== pageSize
            const fromTimeHasChanges = qFromTime !== filter.fromTime
            const toTimeHasChanges = qToTime !== filter.toTime
            const usernameHasChanges = qUsername !== filter.username
            const typeTransactionHasChanges = qTypeTransaction !== filter.typeTransaction
            const userRoleHasChanges = qUserRole !== filter.userRole
            const selectedAgentHasChanges = prevSelectedAgent.current !== selectedAgent
            const directHasChanges = qDirect !== filter.direct
            const userTransfersNotLoadedYet = userTransfers === null
            
            if (pageHasChanges
                || pageSizeHasChanges
                || fromTimeHasChanges
                || toTimeHasChanges
                || fromTimeHasChanges
                || userRoleHasChanges
                || usernameHasChanges
                || typeTransactionHasChanges
                || selectedAgentHasChanges
                || directHasChanges
                || userTransfersNotLoadedYet){
                console.group('Changes useUserTransfersWatcher')
                console.log('fromTimeHasChanges', fromTimeHasChanges)
                console.log('toTimeHasChanges', toTimeHasChanges)
                console.log('userRoleHasChanges', userRoleHasChanges)
                console.log('usernameHasChanges', usernameHasChanges)
                console.log('selectedAgentHasChanges', selectedAgentHasChanges)
                console.log('pageHasChanges', pageHasChanges)
                console.log('pageSizeHasChanges', pageSizeHasChanges)
                console.log('typeTransactionHasChanges', typeTransactionHasChanges)
                console.log('userTransfersNotLoadedYet', userTransfersNotLoadedYet)
                console.groupEnd()

                const filterNew: UserTransfersFilter = {
                    fromTime: qFromTime,
                    toTime: qToTime,
                    userRole: qUserRole,
                    username: qUsername,
                    typeTransaction: qTypeTransaction,
                    direct: qDirect
                }

                const pageNew = qPage !== null ? qPage : 1
                const itemsPerPageNew = qPageSize !== null ? qPageSize : pageSize
                const childID = selectedAgent !== null ? selectedAgent.user_id : null
                
                getUserTransfersByAgent(pageNew, itemsPerPageNew, childID, filterNew)

                prevSelectedAgent.current = selectedAgent
            }
        }
    }, [currentPage, disabled, filter.direct, filter.fromTime, filter.toTime, filter.typeTransaction, filter.userRole, filter.username, getUserTransfersByAgent, hasAccess, isUserTransfersLoading, location.search, pageSize, selectedAgent, userTransfers])
}