import { FC, useCallback, useEffect, useState } from "react";
import { ModalInterface } from "../../commons/modal_wrapper/model";
import { ModalWrapper } from "../../commons/modal_wrapper/ModalWrapper";
import {User, UserRole} from "../../../features/user/user";
import { useAgents } from "../../../features/agents/useAgents";
import { useUser } from "../../../features/user/useUser";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { UserCard } from "./UserCard";
import { MoneyInput } from "../../commons/money_input/MoneyInput";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { convertCentsToCurrencyString, convertStringCurrencyToCents } from "../../../features/common_funcs";
import { usePlayersAgents } from "../../../features/manage_players_agents/usePlayersAgents";
import { useSnackbar, VariantType } from "notistack";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {stringToUserRole} from "../../../features/user/userFuncs";

interface DepositWithdrawMoneyProps extends ModalInterface {
    initiator: User
    mode: 'deposit' | 'withdraw'
}

export const DepositWithdrawMoney: FC<DepositWithdrawMoneyProps> = ({ onDone = () => {}, initiator , mode }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { languagePack: {pack: {depositMoneyToUser: depositLang, withdrawMoneyFromUser: withdrawLang}}} = useLanguage()
    const { sendDeposit, isDepositSending, withdrawMoney, isWithdrawLoading } = usePlayersAgents()
    const { selectedAgent } = useAgents()
    const { getOneUser,user } = useUser()
    const [isSenderLoading, setIsSenderLoading] = useState<boolean>(false);
    const [parent, setParent] = useState<User>();
    const [initialLoad, setInitialLoad] = useState<boolean>(true);
    const [value, setValue] = useState<string>('');
    const [ hasError, setHasError ] = useState<boolean>(false)
    const { xxSmallScreen } = useCommonMediaQueries()
    const [parentUserRole, setParentUserRole] = useState<UserRole|null>(null)

    useEffect(() => {
        if (initialLoad) {
            if (user !== null) {
                if (user.user_role === 'platform') {
                    setParent(user)
                    setParentUserRole(stringToUserRole(user.user_role))
                } else if (selectedAgent === null && user) {
                    setParent(user)
                    setParentUserRole(stringToUserRole(user.user_role))
                } else if (selectedAgent !== null) {
                    getOneUser(selectedAgent.user_id, setIsSenderLoading, setParent)
                }
                setInitialLoad(false);
            }
        }
    }, [getOneUser, initialLoad, selectedAgent, user])

    const handleSendClick = () => {
        if (parent) {
            if (mode === 'deposit') {
                sendDeposit(initiator.user_id, convertStringCurrencyToCents(value), parent.user_id, initiator.user_currency ?? 'ARS', () => {
                    sendSnackBar('success', depositLang.moneySuccessfulSentMessageShort)
                    onDone()
                })
            } else {
                withdrawMoney(initiator.user_id, convertStringCurrencyToCents(value), parent.user_id, () => {
                    sendSnackBar('success', withdrawLang.moneySuccessfulWithdrawedMessageShort)
                    onDone()
                })
            }
        }
    }

    const sendSnackBar = useCallback((variant: VariantType, message: string) => {
        enqueueSnackbar(message, { variant });
    }, [enqueueSnackbar])

    return <Stack rowGap={'20px'}>
        <Stack direction={xxSmallScreen ? (mode === 'deposit' ? 'column' : 'column-reverse') : (mode === 'deposit' ? 'row' : 'row-reverse')} columnGap={'6px'}>
            <Stack flex={1}>
                <Typography variant={'caption'}>{mode === 'deposit' ? `${depositLang.senderLabel}:` : `${withdrawLang.toReceiverLabel}:`}</Typography>
                <UserCard user={parent} loading={isSenderLoading}/>
            </Stack>
            <Stack flex={1}>
                <Typography variant={'caption'}>{mode === 'deposit' ? `${depositLang.recipientLabel}:` : `${withdrawLang.withdrawFromLabel}:`}</Typography>
                <UserCard user={initiator}/>
            </Stack>
        </Stack>

        <MoneyInput
            label={`${mode === 'deposit' ? depositLang.depositLabel : withdrawLang.withdrawLabel} ${initiator.user_currency}`}
            value={value}
            onValueChanged={setValue}
            maxAmount={mode === 'deposit' ? (parent && parentUserRole !== null && (parentUserRole !== UserRole.PLATFORM && parentUserRole !== UserRole.OWNER )? convertCentsToCurrencyString(parent.user_balance) : undefined) : convertCentsToCurrencyString(initiator.user_balance)}
            currency={initiator.user_currency}
            hasError={(has) => setHasError(has)}
            disabled={!Boolean(parent)}
        />
        <Stack direction={'row'} flexWrap={'wrap'} gap={'10px'}>
            {quickAmounts.map(amount => {
                const maxAmount = mode === 'deposit' ? (parent ? parent.user_balance : undefined) : initiator.user_balance
                return <Button
                    disabled={maxAmount !== undefined && amount > maxAmount}
                    variant={'contained'}
                    key={amount}
                    onClick={() => {
                        const currentSum = convertStringCurrencyToCents(value)
                        setValue(convertCentsToCurrencyString(currentSum + amount, false, true))
                    }}
                >
                    {convertCentsToCurrencyString(amount, true)}
                </Button>
            })}
            {mode === 'withdraw' && <Button
                variant={'contained'}
                fullWidth
                disabled={initiator.user_balance === 0 || isWithdrawLoading}
                onClick={() => {
                    setValue(convertCentsToCurrencyString(initiator.user_balance, false, true))
                }}
            >
                {withdrawLang.withdrawAllBtn}
            </Button>}
        </Stack>

        <Button
            disabled={hasError || !Boolean(parent)}
            endIcon={isDepositSending || isWithdrawLoading ? <CircularProgress size={16}/> : <></>}
            onClick={handleSendClick}
        >
            {mode === 'deposit' ? depositLang.sendBtn : withdrawLang.withdrawBtn}
        </Button>
    </Stack>
};

export const DepositWithdrawMoneyModal: FC<DepositWithdrawMoneyProps> = (props) => <ModalWrapper component={DepositWithdrawMoney} componentProps={props}/>

const quickAmounts: number[] = [
    10000,
    50000,
    250000,
    500000,
    1000000
]