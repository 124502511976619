import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    typography: {
        fontFamily: 'Poppins, sans-serif'
    },
    palette: {
        mode: 'dark',
        primary: {
            light: '#F7E8D3',
            main: '#d34ecc',
            dark: '#b431ad',
            contrastText: '#18171F'
        },
        secondary: {
            light: '#e77597',
            main: '#e34977', // Cyan
            dark: '#b72c56',
            contrastText: '#fff'
        },
    },
});